#stripe-form input::placeholder {
  color: #aab7c4;
}

#stripe-form .stripe-text-input {
  padding: 18.5px 14px;
  background-color: white;
  font-size: 1.4rem;
  border: 1px solid #c4d5ee;
  border-radius: 5px;
  margin-top: 1.4rem;
}

#stripe-form .stripe-text-input.StripeElement--focus {
  border-color: #006bd6;
}
#stripe-form .stripe-text-input.StripeElement--invalid {
  border-color: #e20f00;
}
